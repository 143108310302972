import styled from 'styled-components'
import { Link } from 'gatsby'

// IMPORTANT: the & is used to increase the specificity of the styled component to avoid builder.io styles overriding it
export const Frame = styled.div`
  & {
    z-index: 800;
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
      display: block;
    }
  }
`

export const FrameTop = styled.div`
  order: 2;
  width: 100%;
  display: flex;
`

export const FrameBottom = styled.div`
  order: 1;
  width: 100%;
`

interface WrapperProps {
  $scroll?: boolean
}
export const Wrapper = styled.div<WrapperProps>`
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    box-shadow: ${(props) =>
      props.$scroll
        ? `0px 100px 80px rgba(0, 0, 0, 0.07),
        0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
        0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
        0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
        0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
        0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802)`
        : 'none'};
  }
`

interface ComponentProps {
  $scroll?: boolean
  $mode?: string
}
export const Component = styled.header<ComponentProps>`
  background: ${({ theme }) => theme.primaryBlack};
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: flex;
    align-items: center;
    height: ${(props) => (props.$scroll ? '7.6rem' : '12rem')};
    background: ${(props) => (props.$mode === 'default' ? props.theme.secondaryWhite : props.theme.primaryWhite)};
  }
`

export const Container = styled.div`
  max-width: ${({ theme }) => theme.widthContainer};
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding: 0 ${({ theme }) => theme.widthDocumentGutter};
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: flex;
    align-items: center;
  }
`

interface BrandProps {
  $position: boolean
}
export const Brand = styled.div<BrandProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 9.4rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    display: ${(props) => (props.$position ? 'block' : 'none')};
    height: auto;
  }
`

export const Logo = styled(Link)`
  display: block;
  img {
    width: 12rem;
    height: 3.94rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
      width: 15.9rem;
      height: 4.198rem;
    }
  }
`

export const ScreenReader = styled.span`
  position: absolute;
  left: -999em;
  right: auto;
`

export const LogoText = styled.path`
  fill: ${({ theme }) => theme.primaryWhite};
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    fill: #101115;
  }
`

export const LogoDarkImage = styled.img`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: block;
  }
`

export const LogoLightImage = styled.img`
  display: block;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`

interface ViewportProps {
  $active: boolean
}
export const Viewport = styled.div<ViewportProps>`
  position: fixed;
  display: ${(props) => (props.$active ? 'block' : 'none')};
  top: 15.4rem;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background: ${({ theme }) => theme.primaryBlack};
  overflow: scroll;
  padding-bottom: 15rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    overflow: initial;
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    display: flex;
    width: 100%;
    padding: 0;
    align-items: center;
    justify-content: space-between;
    background: transparent;
  }
`

export const Navigation = styled.div`
  padding: 2.5rem 4rem 1.5rem 4rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0;
  }
`

export const NavigationList = styled.ul`
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 100%;
    display: flex;
  }
`

export const NavigationListItem = styled.li`
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-left: 3.5rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-left: 7.5rem;
  }
`

export const LinkItem = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: flex;
    justify-content: flex-end;
    margin-left: 7rem;
    width: 25%;
  }
`

interface ButtonProps {
  $scroll?: boolean
}
export const Button = styled.a<ButtonProps>`
  position: ${(props) => (props.$scroll ? 'absolute' : 'inherit')};
  top: ${(props) => (props.$scroll ? '0' : 'auto')};
  bottom: ${(props) => (props.$scroll ? '0' : 'auto')};
  transition: 0.5s ease background;
  display: ${(props) => (props.$scroll ? 'flex' : 'inline-block')};
  align-items: ${(props) => (props.$scroll ? 'center' : 'inherit')};
  justify-content: ${(props) => (props.$scroll ? 'center' : 'inherit')};
  padding: ${(props) => (props.$scroll ? '0 2rem' : '1.6rem 2rem')};
  color: ${({ theme }) => theme.primaryWhite};
  background: ${({ theme }) => theme.primaryGreen};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.6rem;
  font-weight: 700;
  border-radius: ${(props) => (props.$scroll ? '0' : '0.5rem')};
  text-decoration: none;
  box-shadow: ${(props) =>
    props.$scroll
      ? `0px 100px 80px rgba(0, 0, 0, 0.07),
      0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
      0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
      0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
      0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
      0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802)`
      : 'none'};
  text-align: center;

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.secondaryGreen};
  }
`

export const ExternalNavigationLinkAlt = styled.a`
  margin-bottom: 3rem;
  transition: 0.5s ease all;
  display: inline-block;
  padding: 1.6rem 2rem;
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.6rem;
  font-weight: 700;
  border-radius: 0.5rem;
  text-decoration: none;
  color: ${({ theme }) => theme.primaryWhite};
  background: ${({ theme }) => theme.primaryGreen};
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.secondaryGreen};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 0;
    padding: 0;
    background: transparent;
    color: ${({ theme }) => theme.primaryBlack};
    font-family: ${({ theme }) => theme.fontName};
    font-size: 1.4rem;
    font-weight: 800;
    text-transform: none;
    letter-spacing: 0px;
    &:hover,
    &:focus {
      color: ${({ theme }) => theme.primaryGreen};
      background: transparent;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    font-size: 1.8rem;
    background: transparent;
  }
`
export const InternalNavigationLinkAlt = styled(Link)`
  margin-bottom: 3rem;
  transition: 0.5s ease all;
  display: inline-block;
  padding: 1.6rem 2rem;
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.6rem;
  font-weight: 700;
  border-radius: 0.5rem;
  text-decoration: none;
  color: ${({ theme }) => theme.primaryWhite};
  background: ${({ theme }) => theme.primaryGreen};
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.secondaryGreen};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 0;
    padding: 0;
    background: transparent;
    color: ${({ theme }) => theme.primaryBlack};
    font-family: ${({ theme }) => theme.fontName};
    font-size: 1.4rem;
    font-weight: 800;
    text-transform: none;
    letter-spacing: 0px;
    &:hover,
    &:focus {
      color: ${({ theme }) => theme.primaryGreen};
      background: transparent;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    font-size: 1.8rem;
    background: transparent;
  }
`

export const ExternalNavigationLink = styled.a`
  display: flex;
  font-family: ${({ theme }) => theme.fontName};
  font-size: 2.6rem;
  font-weight: 800;
  text-decoration: none;
  color: ${({ theme }) => theme.primaryWhite};
  &:hover {
    background: ${({ theme }) => theme.secondaryGreen};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 0;
    padding: 0;
    background: transparent;
    color: ${({ theme }) => theme.primaryBlack};
    font-family: ${({ theme }) => theme.fontName};
    font-size: 1.4rem;
    font-weight: 800;
    text-transform: none;
    letter-spacing: 0px;
    &:hover,
    &:focus {
      color: ${({ theme }) => theme.primaryGreen};
      background: transparent;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    font-size: 1.8rem;
    background: transparent;
  }
`

export const InternalNavigationLink = styled(Link)`
  display: flex;
  font-family: ${({ theme }) => theme.fontName};
  font-size: 2.6rem;
  font-weight: 800;
  text-decoration: none;
  color: ${({ theme }) => theme.primaryWhite};
  &:hover {
    background: ${({ theme }) => theme.secondaryGreen};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 0;
    padding: 0;
    background: transparent;
    color: ${({ theme }) => theme.primaryBlack};
    font-family: ${({ theme }) => theme.fontName};
    font-size: 1.4rem;
    font-weight: 800;
    text-transform: none;
    letter-spacing: 0px;
    &:hover,
    &:focus {
      color: ${({ theme }) => theme.primaryGreen};
      background: transparent;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    font-size: 1.8rem;
    background: transparent;
  }
`

export const NavigationLinkSubHeader = styled.p`
  color: ${({ theme }) => theme.primaryWhite};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.3rem;
  font-weight: 400;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-left: 0;
    color: ${({ theme }) => theme.secondaryBlack};
    display: none;
  }
  margin-bottom: 3rem;
`

export const Hamburger = styled.button`
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  &:focus,
  &:hover {
    opacity: 0.7;
  }
`

export const HamburgerBox = styled.div`
  width: 28px;
  height: 24px;
  display: inline-block;
  position: relative;
`

interface HamburgerInnerProps {
  $active: boolean
}
export const HamburgerInner = styled.div<HamburgerInnerProps>`
  transition-property: none;
  transform: ${(props) => (props.$active ? 'rotate(45deg)' : '0')};
  display: block;
  top: 50%;
  margin-top: -2px;
  width: 28px;
  height: 2px;
  background-color: ${({ theme }) => theme.primaryWhite};
  border-radius: 2px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  &::before,
  &::after {
    transition-property: none;
    content: '';
    display: block;
    width: 28px;
    height: 2px;
    background-color: ${({ theme }) => theme.primaryWhite};
    border-radius: 2px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  &::before {
    top: ${(props) => (props.$active ? '0' : '-7px')};
  }
  &::after {
    transform: ${(props) => (props.$active ? 'rotate(-90deg)' : '0')};
    bottom: ${(props) => (props.$active ? '0' : '-7px')};
  }
`

export const Trigger = styled.div`
  display: block;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`

export const SocialFrame = styled.div`
  padding: 2.5rem 5.5rem;
  border-top: 1px solid #000;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`

export const Dropdown = styled.div`
  position: relative;
  margin-bottom: 2rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 0;
  }
`

interface DropdownTriggerProps {
  $active: boolean
}
export const DropdownTrigger = styled.button<DropdownTriggerProps>`
  transition: 0.5s ease color;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.primaryWhite};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.3rem;
  font-weight: 800;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  &:after {
    content: ':';
    @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
      content: none;
    }
  }
  svg {
    fill: ${({ theme }) => theme.primaryBlack};
    display: none;
    transform: ${(props) => (props.$active ? 'rotate(180deg)' : 'none')};
    transition: 0.5s ease all;
    margin-left: 0.8rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
      display: inline-block;
      margin-bottom: 0.3rem;
    }
  }
  &:hover,
  &:focus {
    cursor: pointer;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 0;
    color: ${({ theme }) => theme.primaryBlack};
    font-family: ${({ theme }) => theme.fontName};
    font-size: 1.4rem;
    font-weight: 800;
    text-transform: none;
    letter-spacing: 0px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    font-size: 1.8rem;
  }
`

interface DropdownViewportProps {
  $active: boolean
}
export const DropdownViewport = styled.div<DropdownViewportProps>`
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    transition: 0.3s cubic-bezier(0.2, 0, 0, 1.6);
    transform: ${(props) => (props.$active ? 'none' : 'scale(0.6) translateY(-20%)')};
    position: absolute;
    top: 4.5rem;
    left: -13rem;
    width: 35.2rem;
    border-radius: 5px;
    box-shadow:
      0px 100px 80px rgba(0, 0, 0, 0.07),
      0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
      0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
      0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
      0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
      0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    background: ${({ theme }) => theme.primaryWhite};
    height: ${(props) => (props.$active ? 'auto' : '0')};
    overflow: ${(props) => (props.$active ? 'unset' : 'hidden')};
    opacity: ${(props) => (props.$active ? '1' : '0')};
    visibility: ${(props) => (props.$active ? 'visible' : 'hidden')};
  }
`

interface DropdownViewportItemTitleProps {
  $current: boolean
}
export const DropdownViewportItemTitle = styled.div<DropdownViewportItemTitleProps>`
  display: flex;
  color: ${(props) => (props.$current ? props.theme.primaryGreen : props.theme.primaryWhite)};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 2.6rem;
  font-weight: 800;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    transition: 0.5s ease all;
    color: ${(props) => (props.$current ? props.theme.primaryGreen : props.theme.tertiaryBlack)};
    font-size: 1.6rem;
    font-weight: 700;
  }
`

export const DropdownViewportItem = styled(Link)`
  text-decoration: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.5rem 3.3rem;
    border-bottom: 1px solid #eff1ed;
    svg {
      transition: 0.5s ease all;
      fill: ${({ theme }) => theme.primaryBlack};
    }
    &:hover ${DropdownViewportItemTitle}, &:focus ${DropdownViewportItemTitle} {
      color: ${({ theme }) => theme.primaryGreen};
    }
    &:hover,
    &:focus {
      svg {
        fill: ${({ theme }) => theme.primaryGreen};
      }
    }
    &:last-of-type {
      border-bottom: 0;
    }
  }
`

export const DropdownViewportItemLede = styled.p`
  margin-left: 4rem;
  color: ${({ theme }) => theme.primaryWhite};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.3rem;
  font-weight: 400;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-left: 0;
    color: ${({ theme }) => theme.secondaryBlack};
  }
`

export const DropdownMobileArrow = styled.svg`
  margin-right: 3rem;
  margin-top: 1.5rem;
  fill: ${({ theme }) => theme.primaryGreen};
  display: block;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
    margin-top: 0;
  }
`
