import React, { MutableRefObject, RefObject, useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { debounce } from 'lodash'
import { createRef, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import Notification from '../Notification/notification'
import Social from '../Social/social'
import {
  Brand,
  Button,
  Component,
  Container,
  Dropdown,
  DropdownMobileArrow,
  DropdownTrigger,
  DropdownViewport,
  DropdownViewportItem,
  DropdownViewportItemLede,
  DropdownViewportItemTitle,
  Frame,
  FrameBottom,
  FrameTop,
  Hamburger,
  HamburgerBox,
  HamburgerInner,
  LinkItem,
  Logo,
  LogoDarkImage,
  LogoLightImage,
  Navigation,
  ExternalNavigationLink,
  InternalNavigationLink,
  ExternalNavigationLinkAlt,
  InternalNavigationLinkAlt,
  NavigationLinkSubHeader,
  NavigationList,
  NavigationListItem,
  ScreenReader,
  SocialFrame,
  Trigger,
  Viewport,
  Wrapper,
} from './header.styles'
import { BuilderIoContentResponse, FooterModel, GlobalModel, HeaderModel } from '../../types/builderio-models'
import { BuilderCustomComponentProps } from '../../types/builderio-ids'

type HeaderLinks = Array<{
  title: string
  id?: string
  link?: string
  text?: string
  subLinks?: Array<{
    title: string
    id: string
    text: string
    link: string
  }>
}>
interface HeaderProps extends BuilderCustomComponentProps {
  showLogoByDefault: boolean
  current: string | boolean
  defaultNotificationBehavior: boolean
  mode: string
  links?: HeaderLinks
}

const { GATSBY_TWITTER_PIXEL_ID: pixelId, GATSBY_TWITTER_PIXEL_EVENT_ID: pixelEventId } = process.env

const theNavigation: HeaderLinks = [
  {
    title: 'Learn',
    subLinks: [
      {
        title: 'The Sporttrade Advantage',
        id: 'sporttrade-advantage',
        text: 'Why probability beats odds',
        link: '/sporttrade-advantage/',
      },
      {
        title: 'Sporttrade 101',
        id: 'faqs',
        text: 'Answers to all your questions',
        link: '/faqs/',
      },
      {
        title: 'Watch & Listen',
        id: 'watch-and-listen',
        text: 'The latest video and audio',
        link: '/watch-and-listen/',
      },
      {
        title: 'Blog, News, & Media',
        id: 'resources',
        text: 'Articles, News, and PR',
        link: '/resources/',
      },
    ],
  },
  {
    title: 'Company',
    subLinks: [
      {
        title: 'About',
        id: 'about',
        text: 'The Sporttrade story',
        link: '/about/',
      },
      {
        title: 'Careers',
        id: 'careers',
        text: 'Join our growing team',
        link: '/careers/',
      },
      {
        title: 'Contact',
        id: 'contact',
        text: 'Reach out and connect',
        link: '/contact/',
      },
    ],
  },
  {
    title: 'Support',
    id: 'support',
    text: '',
    link: 'https://sporttrade.zendesk.com/hc/en-us',
  },
]

const Header = ({ showLogoByDefault, current, defaultNotificationBehavior, mode, links, attributes }: HeaderProps) => {
  const [scroll, setScroll] = useState(false)
  const [active, setActive] = useState(false)
  const [activeKey, setActiveKey] = useState<number | string>()

  const navigationList = links || theNavigation
  const dropdownItems = useMemo(
    () => navigationList.filter((item) => item.subLinks && item.subLinks.length > 0),
    [navigationList],
  )
  const dropdownRefs = useRef(dropdownItems.map(() => createRef<HTMLLIElement>()))

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }
  const toggle = () => {
    if (active) {
      setActive(false)
    } else {
      setActive(true)
    }
  }

  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 25)

    function closeOnBody(event: Event) {
      const { current } = dropdownRefs
      if (!current) return
      const dropdownRefIndex = current.findIndex((ref) => ref.current?.contains(event.target as Node))
      setActiveKey(dropdownItems[dropdownRefIndex]?.title || '')
    }

    window.addEventListener('scroll', debouncedHandleScroll)
    document.addEventListener('click', closeOnBody)
    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll)
      document.removeEventListener('click', closeOnBody)
    }
  }, [activeKey, dropdownRefs])

  const data: {
    allBuilderModels: {
      oneGlobal: {
        data: Pick<GlobalModel, 'appleAppDownloadLink' | 'androidAppDownloadLink' | 'appsflyerAppDownloadLink'>
      }
      oneHeader: {
        content: BuilderIoContentResponse<HeaderModel, 'data'>
      }
      oneFooter: {
        data: Pick<FooterModel, 'socialIcons' | 'socialIconsTitle'>
      }
    }
  } = useStaticQuery(graphql`
    {
      allBuilderModels {
        oneGlobal {
          data {
            androidAppDownloadLink
            appleAppDownloadLink
            appsflyerAppDownloadLink
          }
        }
        oneHeader(options: { enrich: true, cachebust: true }) {
          content
        }
        oneFooter(options: { enrich: true, cachebust: true }) {
          data {
            socialIconsTitle
            socialIcons
          }
        }
      }
    }
  `)

  const { brandName, sporttradeLogo, sporttradeLogoLight, notification, showNotification } =
    data.allBuilderModels.oneHeader.content.data

  const { appleAppDownloadLink, appsflyerAppDownloadLink } = data.allBuilderModels.oneGlobal.data

  const { socialIcons, socialIconsTitle } = data.allBuilderModels.oneFooter.data

  return (
    <Frame {...attributes}>
      <Helmet>
        <script type="text/javascript">{`!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
          },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
          a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
          twq('config', ${pixelId});`}</script>
        <script type="text/javascript">{`twq('event', ${pixelEventId}, { phone_number: document.getElementById('phone')?.value }); `}</script>
      </Helmet>
      <FrameTop>
        {showNotification && defaultNotificationBehavior && (
          <Notification
            viewport="all"
            notificationType={'alert'}
            attrTarget={notification.openLinkInNewTab ? '_blank' : ''}
            attrRel={notification.openLinkInNewTab ? 'noreferrer nofollow' : ''}
            textFull={notification.titleFull}
            textTruncated={notification.titleTruncated}
            target={notification.link}
            alertColor={notification.alertColor}
            successColor={notification.successColor}
          />
        )}
        <Notification
          viewport={showNotification && defaultNotificationBehavior ? 'medium' : 'none'}
          notificationType={'success'}
          attrTarget=""
          attrRel=""
          textFull={'Download'}
          textTruncated={'Download'}
          target={appleAppDownloadLink}
          alertColor={notification.alertColor}
          successColor={notification.successColor}
        />
      </FrameTop>
      <FrameBottom>
        <Wrapper $scroll={defaultNotificationBehavior ? scroll : true}>
          <Component $scroll={defaultNotificationBehavior ? scroll : true} $mode={mode}>
            <Container>
              <Brand $position={scroll || showLogoByDefault}>
                <Logo to="/">
                  <ScreenReader>{brandName}</ScreenReader>
                  <LogoDarkImage width="169" height="55" src={sporttradeLogo.value.data.image.file} />
                  <LogoLightImage width="169" height="55" src={sporttradeLogoLight.value.data.image.file} />
                </Logo>
                <Trigger>
                  <Hamburger onClick={toggle}>
                    <ScreenReader>Open/Close Menu</ScreenReader>
                    <HamburgerBox>
                      <HamburgerInner $active={active} />
                    </HamburgerBox>
                  </Hamburger>
                </Trigger>
              </Brand>
              <Viewport $active={active}>
                <Navigation>
                  <nav>
                    <NavigationList>
                      {navigationList.map((item, i) => {
                        // Dropdown
                        if (item?.subLinks && item.subLinks.length > 0) {
                          const dropdownIndex = dropdownItems.findIndex((d) => d.title === item.title)
                          return (
                            <NavigationListItem key={i} ref={dropdownRefs.current[dropdownIndex]}>
                              <Dropdown>
                                <DropdownTrigger
                                  $active={activeKey === item.title}
                                  onClick={() => setActiveKey(item.title !== activeKey ? item.title : '')}
                                >
                                  {item.title}
                                  <svg width="9" height="8" viewBox="0 0 9 8" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.366 7a1 1 0 0 1-1.732 0L.603 1.75a1 1 0 0 1 .866-1.5H7.53a1 1 0 0 1 .866 1.5L5.366 7Z" />
                                  </svg>
                                </DropdownTrigger>
                                <DropdownViewport $active={activeKey === item.title}>
                                  {item.subLinks.map((inner, i) => (
                                    <DropdownViewportItem to={inner.link} key={i}>
                                      <div>
                                        <DropdownViewportItemTitle $current={current === inner.id}>
                                          <div>
                                            <DropdownMobileArrow
                                              width="8"
                                              height="9"
                                              viewBox="0 0 8 9"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path d="M7.5 3.634a1 1 0 0 1 0 1.732L2.25 8.397a1 1 0 0 1-1.5-.866V1.47a1 1 0 0 1 1.5-.866L7.5 3.634Z" />
                                            </DropdownMobileArrow>
                                          </div>
                                          <p>{inner.title}</p>
                                        </DropdownViewportItemTitle>
                                        <DropdownViewportItemLede>{inner.text}</DropdownViewportItemLede>
                                      </div>
                                      <div>
                                        <svg
                                          width="8"
                                          height="9"
                                          viewBox="0 0 8 9"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M7.5 3.634a1 1 0 0 1 0 1.732L2.25 8.397a1 1 0 0 1-1.5-.866V1.47a1 1 0 0 1 1.5-.866L7.5 3.634Z" />
                                        </svg>
                                      </div>
                                    </DropdownViewportItem>
                                  ))}
                                </DropdownViewport>
                              </Dropdown>
                            </NavigationListItem>
                          )
                        }

                        const isExternalLink = item?.link?.startsWith('http')
                        const linkAttributes: { href: string; target: string; rel: string } | { to: string } =
                          isExternalLink
                            ? {
                                href: item?.link || '',
                                target: '_blank',
                                rel: 'noopener nofollow',
                              }
                            : {
                                to: item.link || '',
                              }

                        // Simple link with text description (for mobile)
                        if (item?.text) {
                          return (
                            <NavigationListItem key={i}>
                              {isExternalLink ? (
                                <ExternalNavigationLink {...linkAttributes}>{item.title}</ExternalNavigationLink>
                              ) : (
                                <InternalNavigationLink {...(linkAttributes as { to: string })}>
                                  {item.title}
                                </InternalNavigationLink>
                              )}
                              <NavigationLinkSubHeader>{item.text}</NavigationLinkSubHeader>
                            </NavigationListItem>
                          )
                        }

                        // Simple link with NO text description (for mobile)
                        return (
                          <NavigationListItem key={i}>
                            {isExternalLink ? (
                              <ExternalNavigationLinkAlt {...linkAttributes}>{item.title}</ExternalNavigationLinkAlt>
                            ) : (
                              <InternalNavigationLinkAlt {...(linkAttributes as { to: string })}>
                                {item.title}
                              </InternalNavigationLinkAlt>
                            )}
                          </NavigationListItem>
                        )
                      })}
                    </NavigationList>
                  </nav>
                </Navigation>
                <SocialFrame>
                  <Social socialIconsTitle={socialIconsTitle} socialIcons={socialIcons} />
                </SocialFrame>
                <LinkItem>
                  <Button
                    href={appsflyerAppDownloadLink}
                    $scroll={defaultNotificationBehavior ? scroll : true}
                    target="_blank"
                    rel="noreferrer nofollow"
                  >
                    Download Now
                  </Button>
                </LinkItem>
              </Viewport>
            </Container>
          </Component>
        </Wrapper>
      </FrameBottom>
    </Frame>
  )
}

export default Header
